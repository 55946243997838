<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
      <v-btn color="primary" @click="createNew()">Создать счет</v-btn></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
      @click="onClickRow($event)"
      @update-data="getBalance($event.filters)"
    >
      <template v-slot:footer>
        <div class="f-table-footer">
          <div class="f-table-row">
            <div style="margin: 6px 6px" v-if="$refs.table">
              Показано: {{ $refs.table.pager.count }}, Приход:
              <a-view-number :value="balance.debit" />, Расход: <a-view-number :value="balance.credit" />, Баланс:
              <a-view-number :value="balance.debit - balance.credit" />
            </div>
          </div>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/financeDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.finance.list,
      url: "/kazan/finance",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
      balance: {
        debit: 0,
        credit: 0,
      },
    };
  },
  created() {
    this.$root.title = "Финансы";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async getBalance(filter = {}) {
      const response = await this.$axios.get("/kazan/finance/balance", {
        params: { filters: filter},
      });
      this.balance = response.data.data;
      console.log(response.data.data);
    },
  },
};
</script>
